<template>
  <div class="stack-details">
    <div class="stack-details__container">
      <h3
        v-if="wizard"
        class="mb-4">
        {{ $t('createStack') }}
      </h3>

      <div>
        <v-text-field
          v-model.trim="$v.stack.name.$model"
          data-cy="stack-name-field"
          :label="$t('stackName')"
          :error-messages="nameErrors"
          :counter="$static.SLUG_LIMIT"
          required
          class="required-field"
          @input="setCanonicalInput"
          @blur="$v.stack.name.$touch()"/>

        <v-text-field
          v-model="$v.stack.canonical.$model"
          :label="$t('canonicalStackName')"
          :hint="$t('canonicalUniqueId')"
          :error-messages="canonicalErrors"
          :value="stack.canonical"
          persistent-hint
          required
          class="required-field"
          disabled/>

        <v-textarea
          v-if="!hiddenFields.includes('description')"
          v-model="$v.stack.description.$model"
          data-cy="stack-description-field"
          :label="$t('forms.fieldDescription')"
          :error-messages="descriptionErrors"
          required
          class="required-field"
          @blur="$v.stack.description.$touch()"/>

        <v-autocomplete
          v-model="$v.stack.service_catalog_source_canonical.$model"
          data-cy="stack-cr-field"
          :label="$t('CatalogRepository')"
          :disabled="!availableSCS.length"
          :items="availableSCS"
          :error-messages="scsErrors"
          :messages="showNoCatalogRepositoriesMessage"
          content-class="scs-menu"
          required
          class="required-field"
          item-text="name"
          item-value="canonical"
          @blur="$v.stack.service_catalog_source_canonical.$touch()">
          <template #item="{ item }">
            <v-list-item-content class="stack-cr__item">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  {{ $tc('stackCount', item.stack_count) }} • {{ item.url }} •
                  <v-icon>fa fa-code-branch</v-icon>
                  {{ item.branch }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-combobox
          v-if="!hiddenFields.includes('keywords')"
          v-model="$v.stack.keywords.$model"
          data-cy="stack-keyword-field"
          :label="$t('catalogKeywords')"
          :error-messages="keywordsErrors"
          chips
          clearable
          flat
          multiple
          required
          class="required-field"
          append-icon=""
          @blur="$v.stack.keywords.$touch()">
          <template #selection="data">
            <v-chip
              :input-value="data.selected"
              close
              @click:close="removeKeyword(data.item)">
              <strong>{{ data.item }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </div>
    </div>

    <v-divider
      v-if="wizard"
      class="mb-6 mt-4"/>

    <div
      v-if="wizard"
      class="stack-details__actions">
      <slot
        name="actions"
        :is-form-valid="!$v.$invalid">
        <CyButton
          variant="secondary"
          @click="$emit('previous')">
          {{ $t('forms.back') }}
        </CyButton>

        <CyButton
          data-cy="stack-creation-continue-btn"
          :disabled="$v.$invalid"
          @click.stop="handleClickOnContinue">
          {{ $t('forms.btnContinue') }}
        </CyButton>
      </slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import REGEX from '@/utils/config/regex'
import { SLUG_LIMIT } from '@/utils/plugins/get-slug'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'CyWizardStackDetails',
  props: {
    wizard: {
      type: Boolean,
      default: false,
    },
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  validations () {
    return {
      stack: _.omit({
        name: {
          required,
          maxLength: maxLength(SLUG_LIMIT),
        },
        description: { required },
        keywords: { required },
        canonical: {
          required,
          isValidCanonical: (can) => REGEX.PROJECT_NAME.test(can),
        },
        service_catalog_source_canonical: { required },
      }, this.hiddenFields),
    }
  },
  data: ({ hiddenFields }) => ({
    stack: _.omit({
      service_catalog_source_canonical: undefined,
      canonical: null,
      name: null,
      description: null,
      keywords: [],
    }, hiddenFields),
  }),
  computed: {
    ...mapState('organization', {
      availableSCS: (state) => _.filter(state.available.catalogRepositories, 'credential_canonical'),
    }),
    ...mapState('organization/infraImport', {
      stateStack: (state) => state.stack,
    }),
    $static: () => ({
      SLUG_LIMIT,
    }),
    nameErrors () {
      const { $dirty, required, maxLength } = this.$v.stack.name
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!maxLength) errors.push(this.$t('forms.fieldMaxLength', { number: SLUG_LIMIT }))
      return errors
    },
    canonicalErrors () {
      const { $dirty, required, isValidCanonical } = this.$v.stack.canonical
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isValidCanonical) errors.push(this.$t('validCanonical'))
      return errors
    },
    scsErrors () {
      const { $dirty, required } = this.$v.stack.service_catalog_source_canonical
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    descriptionErrors () {
      const { $dirty, required } = this.$v.stack.description
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    keywordsErrors () {
      const { $dirty, required } = this.$v.stack.keywords
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    showNoCatalogRepositoriesMessage () {
      return _.isEmpty(this.availableSCS)
        ? this.$t('noCatalogRepository')
        : ''
    },
  },
  watch: {
    availableSCS: {
      handler (newVal) {
        if (_.size(newVal) === 1) {
          this.$set(this.$v.stack.service_catalog_source_canonical, '$model', newVal[0]?.canonical)
        }
      },
      deep: true,
      immediate: true,
    },
    '$v.$invalid' (newValue) {
      this.$emit('is-valid', !newValue)
    },
  },
  mounted () {
    this.$v.$reset()

    this.stack = _.merge(this.stack, this.stateStack)
  },
  beforeDestroy () {
    this.$emit('is-valid', false)
  },
  methods: {
    removeKeyword (item) {
      this.stack.keywords.splice(this.stack.keywords.indexOf(item), 1)
    },
    setCanonicalInput (e) {
      this.$v.stack.canonical.$model = e.trim().split(' ').join('_').toLowerCase()
    },
    handleClickOnContinue () {
      const { stack, username } = this

      this.$emit('next', { ...stack, author: stack.author || username })
    },
  },
  i18n: {
    messages: {
      en: {
        canonicalStackName: 'Canonical Stack Name',
        canonicalUniqueId: 'This is a unique identifier for your project.',
        catalogKeywords: 'Keywords',
        createStack: 'Create a Stack',
        noCatalogRepository: 'No service @:catalogRepositories defined yet',
        stackName: 'Stack Name',
        validCanonical: 'Only alphanumeric characters separated by _- are allowed. Minimum length is 3 chars',
      },
      es: {
        canonicalStackName: 'Nombre único',
        canonicalUniqueId: 'Éste es un identificador único para su proyecto',
        catalogKeywords: 'Palabras clave',
        createStack: 'Crear una stack',
        noCatalogRepository: 'Ningún @:catalogRepositories definido',
        stackName: 'Nombre de la stack',
        validCanonical: 'Solo se permiten caracteres alfanuméricos separados por _-. La longitud mínima es de 3 caracteres.',
      },
      fr: {
        canonicalStackName: 'Nom unique',
        canonicalUniqueId: 'Ceci est un identifiant unique pour votre projet',
        catalogKeywords: 'Mots-clés',
        createStack: 'Créer une stack',
        noCatalogRepository: 'Aucun @:catalogRepositories défini',
        stackName: 'Nom de la stack',
        validCanonical: 'Seuls les caractères alphanumériques séparés par _- sont autorisés. La longueur minimum est de 3 caractères.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$inputs-width: 450px;

.stack-details {
  &__container {
    width: $inputs-width;
    min-width: $inputs-width;
    height: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}

.v-list-item__content.stack-cr__item {
  display: flex;
  width: min-content;

  .v-list-item__title {
    flex-grow: 0;
    color: cy-get-color("primary");
    font-size: $font-size-default !important;
  }

  .v-list-item__subtitle {
    color: cy-get-color("primary", "light-2");

    ::v-deep .fa-code-branch {
      color: cy-get-color("primary", "light-3");
      font-size: $font-size-default;
    }
  }
}
</style>
